import React, { FunctionComponent, useEffect, useState } from 'react';
import Image from '../Image';
import Form from '../form/Form';

interface InlineFormProps {
  contentLeft: {
    subheading: string;
    heading: string;
    content: string;
  };
  thankYouMessage: {
    heading: string;
    content: string;
    image: string;
  };
  formBackgroundImage: string;
}

const InlineForm: FunctionComponent<InlineFormProps> = ({
  contentLeft,
  thankYouMessage,
  formBackgroundImage,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.search : '';

    if (url.includes('?thanks')) {
      setFormSubmitted(true);
    }
  }, []);

  return (
    <section className="bg-lgrey pt-12 md:pt-24 lg:pt-28 pb-16 md:pb-32 lg:pb-40 relative overflow-hidden">
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-6 md:gap-y-0 relative z-10">
        <div className="text-center md:text-left">
          <div className="font-medium text-red">{contentLeft.subheading}</div>
          <h1
            className="max-w-xl my-5 md:my-6 text-navy mx-auto md:ml-0"
            dangerouslySetInnerHTML={{ __html: contentLeft.heading }}
          />
          <p
            className="max-w-md text-body body-form mx-auto md:ml-0"
            style={{ maxWidth: `29.875rem` }}
            dangerouslySetInnerHTML={{ __html: contentLeft.content }}
          />
        </div>
        <div>
          <div className="max-w-1xl relative mx-auto md:ml-0">
            <div className="absolute w-full h-full form-shadow bg-keylines z-0" />
            {!formSubmitted ? (
              <Form setFormSubmitted={setFormSubmitted} />
            ) : (
              <div className="bg-white text-center px-6 py-8 md:py-16 lg:py-28 md:px-8 lg:px-10 md:ml-auto relative z-10">
                <div className="mx-auto max-w-xxxs mb-10 md:mb-12 lg:mb-16">
                  <Image data={thankYouMessage?.image} className="w-full" />
                </div>
                <h2
                  className="h3-small"
                  dangerouslySetInnerHTML={{ __html: thankYouMessage?.heading }}
                />
                <p
                  className="leading-normal text-body mx-auto"
                  style={{ maxWidth: `22rem` }}
                  dangerouslySetInnerHTML={{ __html: thankYouMessage.content }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="absolute inset-x-0 bottom-0 z-0 mx-auto lg:-mb-12"
        style={{ maxWidth: `1600px` }}
      >
        <Image data={formBackgroundImage} className="w-full" loading="eager" />
      </div>
    </section>
  );
};

export default InlineForm;
