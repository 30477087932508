import { Link } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import useIsInViewport from 'use-is-in-viewport';
import LottieAnimation from '~/components/Global/LottieAnimation';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';

interface Props {
  backgroundColour: string;
  subheading?: string;
  heading?: string;
  content?: string;
  image: string;
  imageType: string;
  animationName?: string;
  imagePosition?: string;
  contentAlignment: string;
  headingWidth: string;
  contentWidth: string;
  contentLink: boolean;
  link?: {
    url: string;
    title: string;
  };
  alertNotice?: string;
  noticeContent?: string;
}

const IntersectedLottieAnimation: FunctionComponent = (props) => {
  const [isInViewport, sectionRef] = useIsInViewport({ threshold: 50 });
  return (
    <div ref={sectionRef}>
      <LottieAnimation loop={false} play={isInViewport} {...props} />
    </div>
  );
};

const TwoColImgText: FunctionComponent<Props> = ({
  ...layoutProps
}): ReactElement => {
  return (
    <section
      className={`${
        layoutProps.backgroundColour !== 'white'
          ? `py-12 md:py-24 bg-${layoutProps.backgroundColour}`
          : 'my-16 md:my-32'
      }`}
    >
      <div className="container">
        <div className="grid md:grid-cols-2 gap-12 md:gap-0">
          <div
            className={`order-2 md:flex md:items-center ${
              layoutProps.imagePosition === 'left' ? 'md:order-1' : 'md:order-2'
            }`}
          >
            {(typeof layoutProps.imageType === 'undefined' ||
              layoutProps.imageType === 'image') && (
              <Image data={layoutProps.image} className="w-full" />
            )}

            {layoutProps.imageType === 'animation' &&
              layoutProps.animationName && (
                <IntersectedLottieAnimation
                  lazyData={layoutProps.animationName}
                  className="w-full"
                />
              )}
          </div>
          <div
            className={`order-1 md:pl-10 md:flex md:items-center ${layoutProps.contentAlignment ===
              'center' && 'md:justify-center'} ${
              layoutProps.imagePosition === 'left'
                ? 'md:order-2 xl:pl-32'
                : 'md:order-1 xl:pr-16'
            }`}
          >
            <div>
              <TextCard
                subheading={layoutProps.subheading}
                subheadingClasses={`${layoutProps.backgroundColour === 'navy' &&
                  'text-teal opacity-100'}`}
                heading={layoutProps.heading}
                headingClasses={`max-w-md md:max-w-${layoutProps.headingWidth}`}
                content={layoutProps.content}
                contentClasses={`max-w-md md:max-w-${
                  layoutProps.contentWidth
                } ${layoutProps.backgroundColour === 'navy' &&
                  'text-white opacity-75'}`}
              />
              {layoutProps.link && (
                <Link to={layoutProps.link.url} className="text-link">
                  {layoutProps.link.title}
                </Link>
              )}
              {layoutProps.alertNotice && (
                <div
                  className="notice max-w-md md:max-w-sm"
                  dangerouslySetInnerHTML={{
                    __html: layoutProps.noticeContent,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TwoColImgText;
