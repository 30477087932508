import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Lottie from 'react-lottie-player';

interface LottieProps {
  className?: string;
  data?: object;
  lazyData?: string;
  play: boolean;
  loop?: boolean;
}

const LottieAnimation: FunctionComponent<LottieProps> = ({
  className = '',
  data,
  lazyData,
  play = true,
  ...props
}): ReactElement => {
  const [animationData, setAnimationData] = useState(data || null);

  useEffect(() => {
    if (lazyData?.length) {
      import(`../../data/animations/${lazyData}.json`).then((d) => {
        setAnimationData(d);
      });
    }
  }, []);

  return useMemo(() => {
    return (
      animationData && (
        <div className={`pointer-events-none w-full h-full ${className}`}>
          <Lottie
            height={'100%'}
            width={'100%'}
            animationData={animationData}
            play={play}
            {...props}
          />
        </div>
      )
    );
  }, [play, animationData]);
};

export default LottieAnimation;
