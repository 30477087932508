import { useAnimation } from 'framer-motion';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from '../Image';

interface PricingMatrixProps {
  heading: string;
  content: string;
  cards: [
    {
      color: string;
      icon: string;
      location: string;
      cardLabel: string;
      rates: [
        {
          stat: string;
          preappend: string;
          addOptionalLabel: boolean;
          label: string;
        }
      ];
      information: [
        {
          heading: string;
          smallHeading: boolean;
          description: string;
          stat: string;
        }
      ];
      addTerms: boolean;
      terms: string;
    }
  ];
  informationIcon: string;
  informationHeading: string;
  informationParagraphOne: string;
  informationParagraphTwo: string;
}

const PricingMatrix: FunctionComponent<PricingMatrixProps> = ({
  heading,
  content,
  cards,
  informationIcon,
  informationHeading,
  informationParagraphOne,
  informationParagraphTwo,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section className="pricing-matrix bg-white py-12 md:pt-20 lg:pt-24 md:pb-32 lg:pb-36">
      {/* Header */}
      <div className="container text-center">
        <div className="mx-auto max-w-3xl">
          <h1
            className="mb-4 md:mb-5"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <p
            className="opacity-70"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
      {/* Pricing Grid */}
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-y-0 md:gap-x-2 lg:gap-x-3 py-8 md:pt-16 md:pb-20 lg:pt-20 lg:pb-28">
        {cards?.map((item, i) => {
          const highlight = [''];

          if (item?.color === 'green') {
            highlight.push('bg-teal');
          } else if (item?.color === 'purple') {
            highlight.push('bg-newPurple');
          } else {
            highlight.push('bg-red');
          }
          return (
            <div key={i} className="border border-dgrey relative">
              <div
                className={`${highlight.join(
                  ' '
                )} w-5/6 h-2 mx-auto absolute inset-x-0 top-bar-pricing`}
              />
              {/* Location */}
              <div className="text-center bg-lgrey flex items-center justify-center p-4">
                <div className="h-5 md:h-6 w-auto mr-2 lg:mr-4">
                  <Image
                    data={item?.icon}
                    className="w-full h-full object-contain"
                    loading="eager"
                  />
                </div>
                <h3
                  className="text-base font-normal text-black my-0 leading-none"
                  dangerouslySetInnerHTML={{ __html: item?.location }}
                />
              </div>
              {/* Rates */}
              <div className="border-b border-dgrey px-4 py-6 md:py-8 text-center rates-section">
                <span
                  className="opacity-60 text-xs mb-2 md:mb-3 block leading-none"
                  dangerouslySetInnerHTML={{ __html: item?.cardLabel }}
                />
                <div className="space-y-4 xl:space-y-0 xl:flex xl:space-x-6 xl:justify-center">
                  {item?.rates?.map((rate, j) => {
                    return (
                      <div key={j} className="leading-none relative">
                        <span className="font-medium">
                          <span
                            className={`text-34px ${
                              item?.rates?.length > 1
                                ? 'md:text-40px '
                                : 'md:text-48px '
                            }`}
                            dangerouslySetInnerHTML={{ __html: rate?.stat }}
                          />
                          <span
                            className="text-20px md:text-24px"
                            dangerouslySetInnerHTML={{
                              __html: ' ' + rate?.preappend,
                            }}
                          />
                        </span>
                        {rate?.addOptionalLabel && rate?.label && (
                          <span
                            className="opacity-60 text-xs block mt-2 md:mt-3 xl:absolute xl:-bottom-4 xl:inset-x-0"
                            dangerouslySetInnerHTML={{ __html: rate?.label }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* Information */}
              <div className="px-5 xl:px-8 py-4 md:py-8 lg:py-10">
                {item.information.map((info, l) => {
                  return (
                    <div
                      key={l}
                      className={` ${
                        l + 1 === item.information.length
                          ? 'border-t border-dotted border-dgrey'
                          : ''
                      } ${info.smallHeading ? 'pb-3 md:pb-4' : 'py-3 md:py-4'}`}
                    >
                      <div className="font-medium text-xs sm:text-sm leading-none flex justify-between">
                        <span
                          className={`${
                            info.smallHeading ? 'opacity-60 font-normal' : ''
                          }`}
                          dangerouslySetInnerHTML={{ __html: info.heading }}
                        />
                        <span dangerouslySetInnerHTML={{ __html: info.stat }} />
                      </div>
                      {info.description && (
                        <span
                          className="text-13px opacity-60 block leading-tight w-3/4 md:w-2/3 mt-2"
                          dangerouslySetInnerHTML={{ __html: info.description }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {/* Terms */}
              {item.addTerms && item?.terms && (
                <p
                  className="opacity-60 text-xxs leading-tight mx-5 xl:mx-8 mb-5 md:mb-8"
                  dangerouslySetInnerHTML={{ __html: item?.terms }}
                />
              )}
              <div
                className={`${highlight.join(
                  ' '
                )} w-5/6 h-2 mx-auto absolute inset-x-0 bottom-bar-pricing`}
              />
            </div>
          );
        })}
      </div>
      {/* Information */}
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-y-0 md:gap-x-4 lg:gap-x-8 xl:gap-x-10 pt-8 md:pt-12 lg:pt-20 border-t border-dgrey">
        <div>
          <div className="flex lg:items-center">
            <div className="w-5 h-5 mt-2 lg:mt-0 mr-3 lg:w-6 lg:h-6">
              <Image
                data={informationIcon}
                className="w-full h-full object-contain"
                loading="eager"
              />
            </div>
            <h2
              className="h2-small mt-0"
              dangerouslySetInnerHTML={{ __html: informationHeading }}
            />
          </div>
        </div>
        <div>
          <p
            className="opacity-70"
            dangerouslySetInnerHTML={{ __html: informationParagraphOne }}
          />
        </div>
        <div>
          <p
            className="opacity-70"
            dangerouslySetInnerHTML={{ __html: informationParagraphTwo }}
          />
        </div>
      </div>
    </section>
  );
};

export default PricingMatrix;
