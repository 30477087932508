import React, { FunctionComponent, useState } from 'react';

interface AccordionProps {
  title: string;
  children: string;
  numbers?: boolean;
  index?: number;
}

const Accordion: FunctionComponent<AccordionProps> = ({
  title,
  children,
  numbers = false,
  index,
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="accordion-wrapper border-b border-dgrey py-4 text-left">
      <div
        className={`accordion-title font-medium text-navy outline-none flex items-center ${
          isOpen ? 'open' : ''
        }`}
        onClick={() => setOpen(!isOpen)}
      >
        {!numbers ? (
          <span className="text-red w-8 text-left inline-block">
            {isOpen ? '-' : '+'}
          </span>
        ) : (
          <span className="text-navy w-8 text-left inline-block">
            0{index + 1}
          </span>
        )}

        {title}

        {numbers && (
          <span className="text-red w-8 text-left ml-auto">
            {isOpen ? '-' : '+'}
          </span>
        )}
      </div>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        <div
          className="accordion-content text-xs pl-8 pt-2"
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </div>
    </div>
  );
};
export default Accordion;
