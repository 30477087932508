import { motion, useAnimation } from 'framer-motion';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Link } from 'gatsby';

interface AltCtaProps {
  heading: string;
  content: string;
  link: {
    url: string;
    title: string;
  };
}

const AltCta: FunctionComponent<AltCtaProps> = ({ heading, content, link }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variantUp = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
  };

  const variantLeft = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 50 },
  };

  const variantRight = {
    visible: { opacity: 1, x: -0 },
    hidden: { opacity: 0, x: -50 },
  };

  return (
    <section className="bg-bluefade relative py-12 md:py-32 lg:py-40">
      <div className="container relative text-center text-white py-16 md:py-0">
        <div className="relative z-10 max-w-lg mx-auto">
          <h2
            className="text-white heading-style-h1 mb-5 md:mb-6"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <p
            className="mb-4 md:mb-5 opacity-70 max-w-sm-md mx-auto"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {link?.url && (
            <Link to={link?.url} target="_blank" className="btn">
              {link?.title}
            </Link>
          )}
        </div>
      </div>
      <motion.div
        className="bg-newPurple circle-bottom alt-cta_shape-1"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={variantUp}
        transition={{ duration: 0.5, delay: 1 * 0.2 }}
      />
      <motion.div
        className="bg-newBlackNavy circle-right alt-cta_shape-2"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={variantRight}
        transition={{ duration: 0.5, delay: 2 * 0.2 }}
      />
      <motion.div
        className="bg-newBlackNavy circle-left alt-cta_shape-3"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={variantLeft}
        transition={{ duration: 0.5, delay: 3 * 0.2 }}
      />
      <motion.div
        className="bg-teal circle-bottom alt-cta_shape-4"
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={variantUp}
        transition={{ duration: 0.5, delay: 4 * 0.2 }}
      />
    </section>
  );
};

export default AltCta;
