import React, { FunctionComponent, ReactElement } from 'react';
import Image from '~/components/Image';

interface Props {
  icon: string;
  heading: string;
  content: string;
  link: {
    title: string;
    url: string;
  };
}
const SecurityNotice: FunctionComponent<Props> = ({
  ...layoutProps
}): ReactElement => {
  return (
    <section className="bg-lgrey my-12 md:my-24">
      <div className="container">
        <div className="py-16 md:py-24 text-center">
          <Image
            data={layoutProps.icon}
            alt=""
            className="w-8 h-8 mx-auto mb-4"
          />
          <h3 dangerouslySetInnerHTML={{ __html: layoutProps.heading }} />
          <p
            className="max-w-md mx-auto"
            dangerouslySetInnerHTML={{ __html: layoutProps.content }}
          />
          {layoutProps.link?.url && (
            <a href={layoutProps.link.url} className="text-link">
              {layoutProps.link.title}
            </a>
          )}
        </div>
      </div>
    </section>
  );
};
export default SecurityNotice;
