import React, { FunctionComponent, ReactElement } from 'react';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';

interface TwoColTextWContentGridProps {
  backgroundColour: string;
  subheading: string;
  heading: string;
  content: string;
  column: [{ heading: string; content: string }];
}

const TwoColTextWContentGrid: FunctionComponent<TwoColTextWContentGridProps> = ({
  ...layoutProps
}): ReactElement => {
  return (
    <section
      className={`${
        /^(lgrey|navy)$/.test(layoutProps.backgroundColour)
          ? `py-12 md:py-24 bg-${layoutProps.backgroundColour}`
          : 'my-12 md:my-24'
      }`}
    >
      <div className="container">
        <div>
          <TextCard
            subheading={layoutProps.subheading}
            subheadingClasses="text-center"
            heading={layoutProps.heading}
            headingClasses="max-w-lg mx-auto text-center"
            content={layoutProps.content}
            contentClasses="max-w-2xl mx-auto text-center"
          />
          <div className="w-full grid md:grid-cols-3 gap-10 md:gap-12 mt-12 md:mt-20">
            {layoutProps.column.map(({ icon, heading, content }, i) => (
              <div key={`engagecol${i}`} className="w-full text-center">
                <Image data={icon} className="h-8 w-auto mx-auto" />
                <p className="mt-4 md:mt-6 text-navy">
                  <strong dangerouslySetInnerHTML={{ __html: heading }} />
                </p>
                <p
                  className="text-sm mt-2"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default TwoColTextWContentGrid;
