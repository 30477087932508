import { useAnimation } from 'framer-motion';
import { Link } from 'gatsby';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from '../Image';

interface PercentAdvisorCardProps {
  heading: string;
  description: string;
  link: {
    url: string;
    title: string;
  };
  image: string;
}

const PercentAdvisorCard: FunctionComponent<PercentAdvisorCardProps> = ({
  heading,
  description,
  link,
  image,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section className="bg-white my-12 md:my-20 lg:my-32">
      <div className="container relative text-center py-16 md:py-0">
        <div className="!h-auto text-white bg-navy px-6 md:px-8 py-12 md:pt-16 lg:pt-20 md:pb-20 lg:pb-24 relative">
          <div className="absolute flex h-2 w-full top-0 inset-x-0">
            <div className="w-1/3 bg-teal" />
            <div className="w-1/3 bg-newPurple" />
            <div className="w-1/3 bg-newBlue" />
          </div>
          <div className="max-w-2xl mx-auto lg:px-6">
            <h2
              className="t-h4 mb-5 md:mb-6 font-medium"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <div className="relative my-4 md:my-5 w-24 md:w-32 mx-auto rounded-full overflow-hidden">
              <Image
                data={image}
                className="w-full h-full contain-image"
                loading="eager"
              />
            </div>
            <p
              className="blockquote-small"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            {link?.url && (
              <div className="mt-5 md:mt-8">
                <Link to={link?.url} target="_blank" className="btn">
                  {link?.title}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PercentAdvisorCard;
