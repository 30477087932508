import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

interface SplitTextProps {
  children: string;
  switchWords: [
    {
      word: string;
    }
  ];
  endHeading: string;
}

const SplitText: FunctionComponent<SplitTextProps> = ({
  children,
  switchWords,
  endHeading,
}) => {
  const [active, setActive] = useState(0);

  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setActive((i) => (i + 1) % switchWords.length);
    }, 4500);

    return () => {
      clearInterval(interval.current);
    };
  }, [interval]);

  const startWords = children ? children.split(' ') : [];
  const endWords = endHeading ? endHeading.split(' ') : [];
  return (
    <AnimateSharedLayout>
      <div>
        {startWords.map((word, i) => (
          <motion.div layout key={children + i} className="z-[20] inline-block">
            <motion.div
              layout
              style={{ display: 'inline-block', willChange: 'transform' }}
            >
              {word + '\u00A0'}
            </motion.div>
          </motion.div>
        ))}
      </div>
      <div>
        <motion.div className="inline-block z-[10] text-bg-red">
          <motion.div
            className="inline-block"
            initial={{ y: '-100%', opacity: 0, skewY: -15 }}
            animate={{ y: 0, opacity: 1, skewY: 0 }}
            transition={{
              duration: 0.4,
              delay: 0.5,
              opacity: { duration: 0.6 },
              skewX: { type: 'spring', bounce: 0.4, delay: 0.7 },
            }}
          >
            <AnimatePresence exitBeforeEnter initial={false}>
              {switchWords.map((word, index) =>
                active === index ? (
                  <motion.span
                    key={word?.word}
                    initial={{ y: '-60%', opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{
                      y: '100%',
                      opacity: 0,
                      transition: {
                        opacity: {
                          duration: 0.3,
                        },

                        duration: 0.4,
                      },
                    }}
                    layout
                    transition={{
                      duration: 0.4,
                      delay: 0,
                      opacity: { duration: 0.6, delay: 0.1 },
                    }}
                    className="inline-block"
                  >
                    {word?.word}
                  </motion.span>
                ) : null
              )}
            </AnimatePresence>
          </motion.div>
        </motion.div>
        <span>{'\u00A0'}</span>
        {endWords?.slice(0, 1).map((word, i) => (
          <motion.div
            layout
            key={children + i}
            className="z-[20] inline-block"
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <motion.div
              transition={{ duration: 0.2, ease: 'easeInOut' }}
              layout
              style={{ display: 'inline-block', willChange: 'transform' }}
            >
              {word + '\u00A0'}
            </motion.div>
          </motion.div>
        ))}
        <span>{'\u00A0'}</span>
      </div>
      {endWords?.slice(1).map((word, i) => (
        <motion.div
          layout
          key={children + i}
          className="z-[20] inline-block"
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <motion.div
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            layout
            style={{ display: 'inline-block', willChange: 'transform' }}
          >
            {word + '\u00A0'}
          </motion.div>
        </motion.div>
      ))}
    </AnimateSharedLayout>
  );
};

export default SplitText;
