import React, { FunctionComponent, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';

interface TestimonialFcProps {
  subheading: string;
  heading: string;
  content: string;
  tab: [
    {
      tabName: string;
      image: string;
    }
  ];
}

const TestimonialFc: FunctionComponent<TestimonialFcProps> = ({
  ...layoutProps
}) => {
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <section className="bg-lgrey py-12 md:py-24">
      <div className="container text-center">
        <TextCard
          subheading={layoutProps.subheading}
          heading={layoutProps.heading}
          content={layoutProps.content}
          contentClasses="max-w-md mx-auto"
        />
        {layoutProps.tab && layoutProps.tab.length && (
          <Tabs
            defaultIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <div className="flex justify-center w-full my-8">
              <TabList
                className={`relative flex tab-list flex-auto tab-is-${tabIndex}`}
              >
                {layoutProps.tab.map(({ tabName }) => (
                  <Tab key={'tab-' + tabName}>{tabName}</Tab>
                ))}
                <div className="tab-indicator"></div>
              </TabList>
            </div>

            {layoutProps.tab.map(({ tabName, image }) => (
              <TabPanel key={'tabpanel-' + tabName}>
                <Image data={image} className="w-full" />
              </TabPanel>
            ))}
          </Tabs>
        )}
      </div>
    </section>
  );
};

export default TestimonialFc;
