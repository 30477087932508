import React, { FunctionComponent, ReactElement } from 'react';
import TextCard from '~/components/Global/TextCard';

interface ComplianceProps {
  subheading: string;
  heading: string;
  content: string;
  image: string;
  feature: [
    {
      heading: string;
      description: string;
    }
  ];
}

const Compliance: FunctionComponent<ComplianceProps> = ({
  ...layoutProps
}): ReactElement => {
  return (
    <section className="py-12 md:py-24 bg-navy">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-10 md:gap-0">
          <div className="md:flex md:items-start md:px-10 xl:px-20">
            <div>
              <TextCard
                subheading={layoutProps.subheading}
                subheadingClasses="text-teal opacity-100"
                heading={layoutProps.heading}
                content={layoutProps.content}
                contentClasses="text-lgrey"
              />
            </div>
          </div>
          <div className="md:flex md:flex-col">
            {layoutProps.feature.map((feature, i) => (
              <div
                key={`feature-${i}`}
                className={`w-full bg-bluefade p-6 md:p-6 xl:pl-12 xl:pr-24 xl:py-6 mb-8 flex flex-wrap items-start justify-between shadow-solid ${i ===
                  0 && 'shadow-solid-teal'} ${i === 1 &&
                  'shadow-solid-purple'} ${i === 2 && 'shadow-solid-blue'}`}
              >
                <h4>{feature.heading}</h4>
                <div
                  className="text-lgrey text-xs mt-2"
                  dangerouslySetInnerHTML={{
                    __html: feature.description,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Compliance;
