import React, { FunctionComponent } from 'react';
import HeartAnimation from '~/components/Donations/HeartAnimation';
import TextCard from '~/components/Global/TextCard';

interface DonationsBeforeAfterProps {
  subheading: string;
  heading: string;
  content: string;
  noticeContent: string;
  tab: [
    {
      tabName: string;
      image: string;
    }
  ];
}

const DonationsBeforeAfter: FunctionComponent<DonationsBeforeAfterProps> = ({
  ...layoutProps
}) => {
  return (
    <section className="bg-lgrey py-12 md:py-24">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-8 md:gap-0">
          <div className="md:px-10 xl:px-20">
            <TextCard
              subheading={layoutProps.subheading}
              heading={layoutProps.heading}
              headingClasses="max-w-md md:max-w-sm"
              content={layoutProps.content}
            />
            <div
              className="notice max-w-md md:max-w-sm"
              dangerouslySetInnerHTML={{
                __html: layoutProps.noticeContent,
              }}
            />
          </div>
          <div>
            <HeartAnimation tabs={layoutProps.tab} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonationsBeforeAfter;
