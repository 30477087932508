import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

interface LinksListProps {
  heading: string;
  links: [
    {
      link: {
        url: string;
        title: string;
        target: string;
      };
    }
  ];
}

const LinksList: FunctionComponent<LinksListProps> = ({ heading, links }) => {
  return (
    <section className="bg-lgrey py-12 md:py-20 lg:py-36">
      <div className="container mb-6 md:mb-8">
        <h2
          className="t-h3 text-center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </div>
      <div className="container">
        <div className="max-w-xl mx-auto grid gap-y-3 md:gap-y-4 md:grid-cols-2 md:gap-x-5">
          {links?.map((item, l) => (
            <div
              key={l}
              className="border-darkBorderGrey border-b pb-3 md:pb-4"
            >
              <Link
                to={item?.link?.url}
                target={item?.link?.target}
                className="text-link"
              >
                {item?.link?.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LinksList;
