import React, { FunctionComponent } from 'react';
import CharityCard from '~/components/Disbursements/CharityCard';
import TextCard from '~/components/Global/TextCard';

interface VettingAndValidationProps {
  subheading: string;
  heading: string;
  content: string;
  charity: [{}];
}

const VettingAndValidation: FunctionComponent<VettingAndValidationProps> = ({
  ...layoutProps
}) => {
  const halfCharity = Math.ceil(layoutProps.charity.length / 2);
  const charityRows = [
    layoutProps.charity.splice(0, halfCharity),
    layoutProps.charity.splice(-halfCharity),
  ];

  return (
    <section className="my-12 md:my-24">
      <div className="container">
        <div className="font-medium text-navy opacity-50 mb-3">
          {layoutProps.subheading}
        </div>
        <div className="flex flex-col md:flex-row md:-ml-12 lg:-ml-24">
          <div className="order-1 md:flex md:items-center md:flex-1 md:order-none md:pl-12 lg:pl-24">
            <div>
              <TextCard heading={layoutProps.heading} />
            </div>
          </div>
          <div className="order-2 mt-4 md:flex md:items-start md:flex-1 md:order-none md:mt-0 md:pl-12 lg:pl-24">
            <div
              className="max-w-md"
              dangerouslySetInnerHTML={{
                __html: layoutProps.content,
              }}
            />
          </div>
        </div>
      </div>
      <div className="verified-partners mt-10">
        <div className="container" style={{ transform: 'translateX(-25%)' }}>
          {charityRows.map((row, i) => (
            <div key={`charity-row-${i}`} className="flex relative my-8">
              {row.map((card, slideIndex) => (
                <div
                  key={`charity-row-${i}-${slideIndex}`}
                  className="relative"
                  style={{ paddingTop: slideIndex % 2 === 0 ? '40px' : '0' }}
                >
                  <CharityCard card={card} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VettingAndValidation;
