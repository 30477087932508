import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

interface TwoColumnSimplePricingProps {
  heading: string;
  cards: [
    {
      subheading: string;
      heading: string;
      description: string;
      link: {
        url: string;
        title: string;
      };
      caption: string;
    }
  ];
}

const TwoColumnSimplePricing: FunctionComponent<TwoColumnSimplePricingProps> = ({
  heading,
  cards,
}) => {
  return (
    <section id="solution" className="bg-white my-12 md:my-24 lg:my-40">
      <div className="container text-center">
        <div className="mx-auto max-w-3xl">
          <h2
            className="t-h3 max-w-md mx-auto mb-4 md:mb-5"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        </div>
      </div>
      <div className="container grid md:grid-cols-2 gap-y-5 md:gap-x-5 md:gap-y-8 lg:gap-y-0 max-w-4xl mx-auto mt-6 md:mt-10 lg:mt-12">
        {cards?.map((item, i) => (
          <div className="text-center flex flex-col" key={i}>
            <div
              className={`relative md:col-span-1 border-dgrey border text-center h-full`}
            >
              <div className="relative z-10 card-padding bg-white h-full pb-16">
                <div className="font-medium text-red leading-none">
                  {item?.subheading}
                </div>
                <h3
                  className="t-h4 mt-2"
                  dangerouslySetInnerHTML={{ __html: item?.heading }}
                />
                <p dangerouslySetInnerHTML={{ __html: item?.description }} />
              </div>
            </div>
            <div className="tran-half inline-block bg-lgrey relative z-10 mx-auto">
              <Link to={item?.link?.url} className="btn">
                {item?.link?.title}
              </Link>
            </div>
            <div className="px-5">
              <p
                className="font-medium"
                dangerouslySetInnerHTML={{ __html: item?.caption }}
              />
            </div>
            {/* <div className={`absolute w-full h-full card-shadow bg-red`} /> */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default TwoColumnSimplePricing;
