import { motion, useAnimation } from 'framer-motion';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from '../Image';

interface IconColumnProps {
  subheading: string;
  heading: string;
  cards: [
    {
      icon: string;
      heading: string;
      content: string;
    }
  ];
}

const IconColumn: FunctionComponent<IconColumnProps> = ({
  heading,
  subheading,
  cards,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section className="bg-white py-12 md:py-32 lg:py-40">
      <div className="container relative grid grid-cols-12 gap-y-8 sm:gap-y-10 md:gap-y-0 md:gap-x-6">
        <div className="col-span-12 md:col-span-6 md:col-span-5 xl:pr-20">
          <div
            className={`font-medium text-navy opacity-50`}
            dangerouslySetInnerHTML={{
              __html: subheading,
            }}
          />
          <h2 dangerouslySetInnerHTML={{ __html: heading }} />
        </div>
        <div className="col-span-12 md:col-span-6 gap-y-6 md:gap-y-8 grid grid-cols-1">
          {cards.map((item, i) => (
            <motion.div
              key={i}
              className="pb-6 md:pb-8 border-b border-dgrey"
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 },
              }}
              transition={{ duration: 0.5, delay: 0.2 * i }}
            >
              <div className="flex gap-x-5 items-center mb-3">
                <div className="w-6 h-6 md:w-8 md:h-8">
                  <Image
                    data={item.icon}
                    className="w-full h-full object-contain"
                  />
                </div>
                <h4
                  className="my-0"
                  dangerouslySetInnerHTML={{ __html: item.heading }}
                />
              </div>
              <p dangerouslySetInnerHTML={{ __html: item.content }} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default IconColumn;
