import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Image from '~/components/Image';
import SplitText from './SplitText';

interface ValidationHeroProps {
  heroContent: {
    subheading: string;
    headingStart: string;
    changeWords: [
      {
        word: string;
      }
    ];
    headingEnd: string;
    content: string;
    button: {
      url: string;
      title: string;
    };
    image: string;
    icon: string;
    logos: [
      {
        logo: string;
      }
    ];
    head;
  };
}

const ValidationHero: FunctionComponent<ValidationHeroProps> = ({
  heroContent: {
    subheading,
    headingStart,
    changeWords,
    headingEnd,
    content,
    button,
    image,
    icon,
    logos,
  },
}) => {
  return (
    <section className="bg-white validation-masthead py-12 md:pt-16 md:pb-20 lg:pt-20 lg:pb-32">
      <div className="container relative z-20 flex flex-col-reverse md:flex-row md:space-x-5 lg:space-x-6 md:justify-between md:items-center">
        {/* Content */}
        <div className="">
          <div className="font-medium text-red">{subheading}</div>
          <h1 className="mb-5 leading-none h1-rotate">
            <SplitText
              switchWords={changeWords}
              // eslint-disable-next-line
              children={headingStart}
              endHeading={headingEnd}
            />
          </h1>
          <p
            className="text-navy mb-2 opacity-70 max-w-sm"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {button?.url && (
            <Link to={button?.url} className="btn">
              {button?.title}
            </Link>
          )}
        </div>
        {/* Media */}
        <div className="md:max-w-1lg w-full md:w-34p lg:w-2/5 xl:w-1/2 relative mb-8 md:mb-0">
          <Image data={image} className="w-full" loading="eager" />
          <div className="absolute bottom-6 md:bottom-12 right-0 w-5/12">
            <Image data={icon} className="w-full" loadin="eager" />
          </div>
        </div>
      </div>
      {/* Logos */}
      {logos?.length > 0 && (
        <div className="container grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 mt-12 md:mt-16 lg:mt-20 gap-6 md:gap-8">
          {logos?.map((item, i) => (
            <div key={i}>
              <div className="relative h-12">
                <Image
                  data={item?.logo}
                  className="w-full h-full contain-image opacity-80"
                  loadin="eager"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default ValidationHero;
