import { Link } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import Image from '~/components/Image';

interface CTAProps {
  heading: string;
  content: string;
  button: {
    url: string;
    title: string;
  };
  image: string;
  mobileImage: string;
  imagePosition: string;
}

const CTA: FunctionComponent<CTAProps> = ({ ...layoutProps }): ReactElement => {
  return (
    <section className="bg-bluefade text-white relative overflow-hidden">
      <div className="container">
        <div
          className={`relative pt-16 md:py-24 md:flex flex-wrap justify-between ${layoutProps.imagePosition !==
            'extend' && 'pb-16'}`}
        >
          <div className="md:w-5/12 md:flex md:items-center">
            <div>
              <h2
                className="text-white max-w-xs"
                dangerouslySetInnerHTML={{ __html: layoutProps.heading }}
              />
              <p
                className="max-w-sm"
                dangerouslySetInnerHTML={{ __html: layoutProps.content }}
              />
              <Link to={layoutProps.button?.url} className="btn">
                {layoutProps.button?.title}
              </Link>
            </div>
          </div>
          {layoutProps.imagePosition === 'contain' && (
            <div className="w-full hidden md:w-7/12 md:flex items-center">
              <Image data={layoutProps.image} className="w-full h-auto" />
            </div>
          )}
          <div
            className={`md:hidden mt-8 ${layoutProps.imagePosition ===
              'extend' && '-mx-4'}`}
          >
            <Image data={layoutProps.mobileImage} className="w-full" />
          </div>
        </div>
      </div>
      {layoutProps.imagePosition === 'extend' && (
        <Image
          data={layoutProps.image}
          className="hidden md:block absolute top-0 right-0 h-full w-full max-w-1/2"
          style={{ position: 'absolute' }}
        />
      )}
    </section>
  );
};
export default CTA;
