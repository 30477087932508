import { motion, useAnimation } from 'framer-motion';
import { Link } from 'gatsby';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from '../Image';

interface LandingHeroProps {
  heading: string;
  content: string;
  button: {
    url: string;
    title: string;
  };
  topLogo: string;
  bottomLogo: string;
  bottomLogoCaption: string;
  imageGallery: [
    {
      image: string;
    }
  ];
}

const LandingHero: FunctionComponent<LandingHeroProps> = ({
  heading,
  content,
  button,
  topLogo,
  bottomLogo,
  bottomLogoCaption,
  imageGallery,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section className="bg-lgrey red-leading validation-masthead py-8 md:pt-32 md:pb-20 lg:pt-40 lg:pb-32">
      <div className="container relative text-center py-16 md:py-0">
        <div className="relative z-10 max-w-2xl mx-auto">
          <div className="max-w-xxxs mx-auto mb-5 w-4/5">
            <Image data={topLogo} className="w-full" loadin="eager" />
          </div>
          <h1
            className="h1-small mb-5"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <div
            className="max-w-md mx-auto alt-hero-content mb-5 md:mb-8"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {button?.url && (
            <Link to={button?.url} target="_blank" className="btn">
              {button?.title}
            </Link>
          )}
        </div>
        <div className="flex space-y-4 md:space-y-0 md:space-x-4 flex-col md:flex-row justify-center items-center mt-8 md:mt-12 lg:mt-20">
          <p dangerouslySetInnerHTML={{ __html: bottomLogoCaption }} />
          <div>
            <Image data={bottomLogo} className="w-full" loading="eager" />
          </div>
        </div>
        {imageGallery[0]?.image && (
          <motion.div
            className="w-1/5 landing-one absolute top-0 left-0"
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 50 },
            }}
            transition={{ duration: 0.5 }}
          >
            <Image
              data={imageGallery[0]?.image}
              className="w-full"
              loadin="eager"
            />
          </motion.div>
        )}
        {imageGallery[1]?.image && (
          <motion.div
            className="w-1/5 landing-two absolute top-4 md:top-8 lg:top-12 right-0"
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 50 },
            }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <Image
              data={imageGallery[1]?.image}
              className="w-full"
              loading="eager"
            />
          </motion.div>
        )}
        {imageGallery[2]?.image && (
          <motion.div
            className="w-1/5 landing-three absolute bottom-4 md:bottom-8 lg:bottom-12 left-4 md:left-6 lg:left-8 xl:left-12"
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 50 },
            }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <Image
              data={imageGallery[2]?.image}
              className="w-full"
              loading="eager"
            />
          </motion.div>
        )}
        {imageGallery[3]?.image && (
          <motion.div
            className="w-1/5 landing-four absolute bottom-0 right-4 md:right-6 lg:right-8 xl:right-12"
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 50 },
            }}
            transition={{ duration: 0.5, delay: 0.9 }}
          >
            <Image
              data={imageGallery[3]?.image}
              className="w-full"
              loading="eager"
            />
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default LandingHero;
