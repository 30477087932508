import React, { FunctionComponent } from 'react';
import Image from '../Image';
interface TwoCardsContentProps {
  heading: string;
  content: string;
  cards: [
    {
      icon: string;
      heading: string;
      description: string;
    }
  ];
  head;
}

const TwoCardsContent: FunctionComponent<TwoCardsContentProps> = ({
  heading,
  content,
  cards,
}) => {
  return (
    <section className="bg-lgrey py-12 md:py-20 lg:py-36">
      <div className="container grid grid-cols-12 gap-y-5 md:gap-x-5 md:space-y-8 lg:gap-y-0">
        <div className="col-span-12 md:col-span-9 lg:col-span-5 lg:pr-8 xl:pr-16">
          <h2
            className="h2-small"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <div
            className="alt-hero-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        {cards?.map((item, i) => (
          <div
            key={i}
            className={`${
              i === 0 ? 'lg:col-start-7' : ''
            } relative col-span-12 md:col-span-6 lg:col-span-3 border-dgrey border`}
          >
            <div className="relative z-10 card-padding bg-white h-full">
              <div className="w-8 h-8 md:w-10 md:h-10 mb-6 md:mb-8">
                <Image data={item?.icon} className="w-full" loading="eager" />
              </div>
              <h3
                className="h3-small"
                dangerouslySetInnerHTML={{ __html: item?.heading }}
              />
              <p
                className="p-small"
                dangerouslySetInnerHTML={{ __html: item?.description }}
              />
            </div>
            <div
              className={`absolute w-full h-full card-shadow ${
                i % 2 === 0 ? 'bg-teal' : 'bg-red'
              }`}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default TwoCardsContent;
