import { graphql, Link, useStaticQuery } from 'gatsby';
import get from 'lodash.get';
import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';
import Chevron from '~/images/icons/chevron.svg';

interface VacanciesProps {
  heading: string;
  content: string;
  link: {
    title: string;
    url: string;
  };
  noVacanciesNotice: string;
  jobs: {
    nodes: [
      {
        title: string;
        acf: {
          location: string;
          job_description_url: string;
        };
      }
    ];
  };
}

const Vacancies: FunctionComponent<VacanciesProps> = ({ ...layoutProps }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpJob(filter: { slug: { ne: "job-title" } }) {
        nodes {
          jobPage {
            location
            jobDescriptionUrl
          }
          title
        }
      }
    }
  `);

  const jobItems = get(data, 'allWpJob.nodes', []);
  return (
    <section id="open-vacancies" className="py-12 vacancies bg-lgrey md:py-24">
      <div className="container max-w-xl text-center">
        <TextCard heading={layoutProps.heading} content={layoutProps.content} />
        <Link to={layoutProps.link.url} className="text-link">
          {layoutProps.link.title}
        </Link>
      </div>

      <div className="container max-w-5xl">
        {jobItems.length === 0 && layoutProps.noVacanciesNotice.length && (
          <div
            className="mt-8 text-center"
            dangerouslySetInnerHTML={{
              __html: layoutProps.noVacanciesNotice,
            }}
          />
        )}

        {jobItems.length > 0 && (
          <div className="mt-10 md:mt-12">
            {jobItems.map(
              ({ title, acf: { job_description_url, location } }, i) => (
                <a
                  href={job_description_url}
                  key={`job-${i}`}
                  className="relative flex flex-wrap justify-between px-8 py-5 mb-4 text-left text-white transition-opacity duration-200 ease-in-out border-l-4 bg-navy border-red lg:mx-16 xl:mx-32 hover:opacity-75"
                >
                  <div className="font-medium">
                    <span>{title}</span>
                  </div>
                  <div className="w-1/2 md:w-3/5">
                    <span>{location}</span>
                  </div>
                  <div className="absolute inset-0 z-10 flex items-center justify-end">
                    <Chevron className="relative z-10 mr-8" />
                  </div>
                </a>
              )
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Vacancies;
