import React, { FunctionComponent, ReactElement } from 'react';

interface TextProps {
  subheading: string;
  heading: string;
  content: string;
}

const TextCardTwoCol: FunctionComponent<TextProps> = ({
  subheading,
  heading,
  content,
}): ReactElement => {
  return (
    <div className="flex flex-wrap md:-ml-12 lg:-ml-24">
      <div className="order-1 md:w-1/2 md:order-none md:pl-12 lg:pl-24">
        <div
          className={`font-medium text-navy opacity-50`}
          dangerouslySetInnerHTML={{
            __html: subheading,
          }}
        />
        <h2
          className="max-w-md"
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
      </div>
      <div className="order-2 mt-4 md:flex md:items-end md:w-1/2 md:order-none md:mt-0 md:pl-12 lg:pl-24">
        <p
          className="max-w-md"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  );
};
export default TextCardTwoCol;
