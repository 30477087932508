import React, { FunctionComponent, ReactElement } from 'react';
import TextCard from '~/components/Global/TextCard';

interface Props {
  subheading?: string;
  heading: string;
  headingWidth: string;
  content?: string;
  contentWidth?: string;
  logo: [
    {
      image: {
        localFile: {
          publicURL: string;
        };
      };
    }
  ];
  gridColumns: string;
  textAlignment: string;
}

const TextWLogosGrid: FunctionComponent<Props> = ({
  ...layoutProps
}): ReactElement => {
  return (
    <section className="my-12 md:my-24">
      <div
        className={`container ${layoutProps.textAlignment === 'center' &&
          'text-center'}`}
      >
        <TextCard
          subheading={layoutProps.subheading}
          heading={layoutProps.heading}
          headingClasses={`max-w-md md:max-w-${
            layoutProps.headingWidth
          } ${layoutProps.textAlignment === 'center' && 'mx-auto'}`}
          content={layoutProps.content}
          contentClasses={`max-w-md md:max-w-${
            layoutProps.contentWidth
          } ${layoutProps.textAlignment === 'center' && 'mx-auto'}`}
        />
        <div className="md:grid-cols-6" />
        <div
          className={`grid grid-cols-3 md:grid-cols-${layoutProps.gridColumns} gap-4 md:gap-8 mt-12`}
        >
          {layoutProps?.logoGrid?.map(({ image }, i) => (
            <div
              key={`logo-${i}`}
              className="flex flex-col items-center justify-center h-16 p-4 bg-lgrey"
            >
              <img
                src={image?.localFile?.publicURL}
                className="object-contain w-20 h-8 mx-auto"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default TextWLogosGrid;
