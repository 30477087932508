import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

interface SimpleTextCardProps {
  subheading: string;
  heading: string;
  content: string;
  link: {
    url: string;
    title: string;
  };
  background: string;
  headingWidth: string;
}

const SimpleTextCard: FunctionComponent<SimpleTextCardProps> = ({
  subheading,
  heading,
  content,
  link,
  background,
  headingWidth,
}) => {
  // Colour Controls
  const sectionClasses = [''];
  const textColour = [''];

  if (background === 'lightGrey') {
    sectionClasses.push('bg-lgrey py-12 md:py-20 lg:py-24');
    textColour.push('text-navy');
  } else if (background === 'lightNavy') {
    sectionClasses.push('bg-bluefade py-12 md:py-20 lg:py-24');
    textColour.push('text-white');
  } else if (background === 'navy') {
    sectionClasses.push('bg-navy py-12 md:py-20 lg:py-24');
    textColour.push('text-white');
  } else {
    sectionClasses.push('bg-white my-12 md:my-20 lg:my-24');
    textColour.push('text-navy');
  }

  return (
    <section className={`${sectionClasses.join(' ')} red-leading`}>
      <div className="container text-center relative z-20">
        <div className="font-medium text-red">{subheading}</div>
        <h2
          className={`mx-auto t-h3 ${
            headingWidth === 'large' ? 'max-w-xl' : 'max-w-sm'
          } ${textColour.join(' ')}`}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <div
          className={`max-w-lg mx-auto text-navy my-6 ${textColour.join(' ')}`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {link?.url && (
          <Link to={link?.url} className="btn">
            {link?.title}
          </Link>
        )}
      </div>
    </section>
  );
};

export default SimpleTextCard;
