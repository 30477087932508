import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';

interface PurposeStackProps {
  background: string;
  subheading: string;
  heading: string;
  content: string;
  featureBox: [
    {
      heading: string;
      content: string;
    }
  ];
}

const PurposeStack: FunctionComponent<PurposeStackProps> = ({
  ...layoutProps
}) => {
  const boxBackgrounds = [
    'shadow-solid-teal',
    'shadow-solid-purple',
    'shadow-solid-blue',
  ];
  return (
    <section
      className={`${
        layoutProps.background === 'grey'
          ? 'bg-lgrey py-12 md:py-24'
          : 'my-12 md:my-24'
      }`}
    >
      <div className="container">
        <div className="grid md:grid-cols-2 gap-8 md:gap-0">
          <div className="md:flex md:items-start md:px-10 xl:px-20">
            <div>
              <TextCard
                subheading={layoutProps.subheading}
                heading={layoutProps.heading}
                content={layoutProps.content}
              />
            </div>
          </div>
          <div className="md:flex md:flex-col mt-2 md:mt-0">
            {layoutProps.featureBox.map(({ heading, content }, i) => (
              <div
                key={`feature-${i}`}
                className={`w-full border border-dgrey p-6 md:p-6 xl:px-12 xl:py-6 mb-8 flex flex-wrap items-start justify-between shadow-solid ${
                  boxBackgrounds[i % boxBackgrounds.length]
                }`}
              >
                <h4 className="md:w-1/4">{heading}</h4>
                <div
                  className="md:w-3/4 text-xxs md:pl-8 mt-2 md:mt-0"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PurposeStack;
