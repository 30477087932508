import React, { FunctionComponent, useEffect, useState } from 'react';

interface FormProps {
  setFormSubmitted: string;
}

const Form: FunctionComponent<FormProps> = ({ setFormSubmitted }) => {
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => {
    setCurrentUrl(typeof window !== 'undefined' ? window.location?.href : '');
  }, []);

  const countries = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Deps',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Central African Rep',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo {Democratic Rep}',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland {Republic}',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea North',
    'Korea South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar, {Burma}',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'St Kitts & Nevis',
    'St Lucia',
    'Saint Vincent & the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];

  return (
    <form
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="POST"
      className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white p-6 md:p-8 lg:p-10 md:ml-auto relative z-10"
    >
      <input type="hidden" name="retURL" value={`${currentUrl}?thanks/`} />
      <input type="hidden" name="oid" value="00DDp000000K4Nq" />
      <input
        id="lead_source"
        name="lead_source"
        type="hidden"
        value="Website Contact Form"
      />

      <div className="col-span-1">
        <label className="hidden" htmlFor="first_name">
          First Name
        </label>
        <input
          required
          className="input-field not-round"
          placeholder="First name"
          id="first_name"
          maxLength="40"
          name="first_name"
          size="20"
          type="text"
        />
      </div>

      <div className="col-span-1">
        <label className="hidden" htmlFor="last_name">
          Last Name
        </label>
        <input
          required
          className="input-field not-round"
          placeholder="Last name"
          id="last_name"
          maxLength="80"
          name="last_name"
          size="20"
          type="text"
        />
      </div>
      <div className="md:col-span-2">
        <label htmlFor="email" className="hidden">
          Email
        </label>
        <input
          required
          className="input-field not-round"
          placeholder="Email"
          id="email"
          maxLength="80"
          name="email"
          size="20"
          type="email"
        />
      </div>
      <div className="md:col-span-2">
        <label className="hidden" htmlFor="phone">
          Phone
        </label>
        <input
          className="input-field not-round"
          placeholder="Phone Number"
          id="phone"
          maxLength="40"
          name="phone"
          size="20"
          type="tel"
        />
      </div>
      <div className="md:col-span-2">
        <label htmlFor="company" className="hidden">
          Company
        </label>
        <input
          required
          className="input-field not-round"
          placeholder="Company"
          id="company"
          maxLength="40"
          name="company"
          size="20"
          type="text"
        />
      </div>

      <div className="md:col-span-2">
        <span className="hidden">Country</span>
        <select
          className="form-field_select input-field not-round"
          id="country"
          name="country"
          title="country"
          required
        >
          <option value="">Country</option>
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>

      <div className="md:col-span-2">
        <label htmlFor="url" className="hidden">
          Website
        </label>
        <input
          className="input-field not-round"
          placeholder="Website"
          title="Please enter a valid website URL"
          pattern="^(https?:\/\/)?[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}.*$"
          id="url"
          maxLength="80"
          name="url"
          size="20"
          type="text"
          required
        />
      </div>

      <div className="md:col-span-2">
        <span className="hidden">Use Case:</span>
        <select
          className="form-field_select input-field not-round"
          id="00NDp000009gu9X"
          name="00NDp000009gu9X"
          title="Use Case"
          required
        >
          <option value="">Which services are you interested in?</option>
          <option value="Donations">Donations</option>
          <option value="verification_compliance">
            Verification &amp; Compliance
          </option>
          <option value="Sales &amp; Marketing Activation">
            Sales &amp; Marketing Activation
          </option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="md:col-span-2">
        <label htmlFor="00NDp000003r5iB" className="hidden">
          Description
        </label>
        <textarea
          className="input-field not-round leading-normal"
          style={{ minHeight: `6rem` }}
          placeholder={`Please share any details you'd like us to know about before we get in touch`}
          name="00NDp000003r5iB"
          id="00NDp000003r5iB"
        ></textarea>
      </div>

      <div className="pt-4">
        <input type="submit" name="submit" />
      </div>
    </form>
  );
};

export default Form;
