import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';
import Accordion from '../Global/Accordion';

interface OurValuesProps {
  subheading: string;
  heading: string;
  content: string;
  value: [
    {
      title: string;
      content: string;
    }
  ];
  image: string;
}

const OurValues: FunctionComponent<OurValuesProps> = ({ ...layoutProps }) => {
  return (
    <section className="bg-lgrey py-12 md:py-24">
      <div className="container">
        <div className="flex flex-wrap md:-ml-12 lg:-ml-24">
          <div className="w-full md:w-1/2 order-2 md:flex md:items-center md:pl-12 lg:pl-24">
            <div>
              <TextCard
                subheading={layoutProps.subheading}
                heading={layoutProps.heading}
                headingClasses="max-w-md"
                content={layoutProps.content}
                contentClasses="max-w-md"
              />
              <div className="mt-8 md:mt-12 border-t border-dgrey">
                {layoutProps.value.map((x, i) => (
                  <Accordion
                    key={`our-values--${i}`}
                    title={x.title}
                    numbers={true}
                    index={i}
                  >
                    {x.content}
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 order-2 mt-12 md:mt-0 md:pl-12 lg:pl-24">
            <Image data={layoutProps.image} className="w-full z-10" />
            <div className="shadow-solid-teal bg-white border border-dgrey py-6 px-8 -ml-6 -mt-24 z-20 relative w-11/12">
              <p className="font-medium text-navy">
                {layoutProps.textBox.heading}
              </p>
              <p className="text-sm">{layoutProps.textBox.content}</p>
              <a href={layoutProps.textBox.link.url} className="text-link">
                {layoutProps.textBox.link.title}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurValues;
