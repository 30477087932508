import React, { FunctionComponent } from 'react';
import Image from '../Image';

interface IconBoxesProps {
  heading: string;
  content: string;
  iconBoxes: [
    {
      icon: string;
      heading: string;
      lists: [
        {
          listItem: string;
        }
      ];
    }
  ];
}

const IconBoxes: FunctionComponent<IconBoxesProps> = ({
  heading,
  content,
  iconBoxes,
}) => {
  return (
    <section className="bg-white validation-masthead pt-4 md:pt-8 lg:pt-14 pb-8 md:pb-12 lg:pb-28">
      {heading && (
        <div className="container text-center flex flex-col justify-center items-center mb-6 md:mb-12 lg:mb-16">
          <h2 dangerouslySetInnerHTML={{ __html: heading }} />
          <p
            className="mx-auto alt-hero-content mt-3 md:mt-5"
            style={{ maxWidth: '790px' }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      )}
      <div className="container relative grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-x-6">
        {iconBoxes.map((item, i) => {
          return (
            <div
              key={i}
              className="p-6 md:p-8 lg:p-12 border-borderGrey border-solid border flex space-x-6 items-start"
            >
              <div className="w-8 h-8 lg:w-14 lg:h-14">
                <Image
                  data={item?.icon}
                  className="w-full h-full object-contain"
                />
              </div>
              <div>
                <h4
                  className="mt-0"
                  dangerouslySetInnerHTML={{ __html: item.heading }}
                />
                <ul className="space-y-1">
                  {item.lists.map((list, k) => {
                    return (
                      <li
                        key={k}
                        className="flex items-start leading-tight text-body"
                      >
                        <div className="w-1 h-1 bullet-point_point bg-black" />
                        <span
                          dangerouslySetInnerHTML={{ __html: list.listItem }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default IconBoxes;
