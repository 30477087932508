import React, { FunctionComponent, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import TextCard from '~/components/Global/TextCard';

interface TabsFWImgProps {
  subheading: string;
  heading: string;
  content: string;
  tab: [
    {
      tabName: string;
      image: string;
    }
  ];
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(undefined);
  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const TabsFWImg: FunctionComponent<TabsFWImgProps> = ({ ...layoutProps }) => {
  const width = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [data, setData] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    setIsMobile(width < 1024);
  }, [width]);

  useEffect(() => {
    const importScripts = async () => {
      const dataImport = await import(
        `../../data/animations/percent-disbursements-quick-easy-${
          !isMobile ? 'toggle' : 'mobile'
        }.json`
      );

      setData(dataImport);
    };

    importScripts();
  }, [isMobile]);

  useEffect(() => {
    setPlay(false);
    setDirection(tabIndex === 0 ? -1 : 1);

    setTimeout(() => {
      setPlay(true);
    }, 100);
  }, [tabIndex]);

  return (
    <section className="bg-lgrey py-12 md:py-24">
      <div className="container text-center">
        <TextCard
          subheading={layoutProps.subheading}
          heading={layoutProps.heading}
          content={layoutProps.content}
          contentClasses="max-w-md mx-auto"
        />

        <div className="react-tabs my-8">
          <ul
            className={`relative flex tab-list text-center flex-auto tab-is-${tabIndex}`}
          >
            {layoutProps.tab.map(({ tabName }, i) => (
              <li
                key={`tab-${tabName}`}
                className={`react-tabs__tab ${tabIndex === i && 'active'}`}
                onClick={() => setTabIndex(i)}
              >
                {tabName}
              </li>
            ))}
            <div className="tab-indicator"></div>
          </ul>
        </div>
        {data?.assets && (
          <Lottie
            animationData={data}
            loop={false}
            goTo={direction === -1 ? 100 : 0}
            play={play}
            direction={direction}
          />
        )}
      </div>
    </section>
  );
};

export default TabsFWImg;
