import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';

interface OurCustomersProps {
  subheading: string;
  heading: string;
  content: string;
  logoGrid: [
    {
      image: string;
    }
  ];
  cta: {
    showSection?: boolean;
    heading: string;
    content: string;
    button: {
      title: string;
      url: string;
    };
  };
}

const OurCustomers: FunctionComponent<OurCustomersProps> = ({
  ...layoutProps
}) => {
  return (
    <section className="my-12 md:my-24">
      <div className="container text-center our-customers">
        <TextCard
          subheading={layoutProps.subheading}
          heading={layoutProps.heading}
          headingClasses="max-w-lg mx-auto"
          content={layoutProps.content}
          contentClasses="max-w-md mx-auto"
        />

        <div className="grid grid-cols-2 gap-4 mt-16 md:grid-cols-5 md:gap-12">
          {layoutProps?.logoGrid?.map(({ image }, i) => (
            <div
              key={`customer-logo-${i}`}
              className="flex flex-col items-center justify-center h-16 bg-lgrey"
            >
              <Image data={image} className="object-contain w-20 h-8 mx-auto" />
            </div>
          ))}
        </div>
        {layoutProps.cta.showSection && (
          <div className="flex flex-wrap justify-between w-full p-8 mt-16 text-left border our-customers__cta md:px-16 md:py-12 shadow-solid border-dgrey">
            <div className="flex items-center justify-start">
              <div>
                <h4>{layoutProps.cta.heading}</h4>
                <div className="max-w-md">{layoutProps.cta.content}</div>
              </div>
            </div>
            <div className="flex items-center">
              <Link
                to={layoutProps.cta.button?.url}
                className="mt-6 btn md:mt-0"
              >
                {layoutProps.cta.button?.title}
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default OurCustomers;
