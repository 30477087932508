import React, { FunctionComponent } from 'react';
import Image from '../Image';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.min.css';

interface TestimonialsProps {
  testimonials: [
    {
      name: string;
      role: string;
      quote: string;
      logo: string;
    }
  ];
}

const Testimonials: FunctionComponent<TestimonialsProps> = ({
  testimonials,
}) => {
  return (
    <section className="bg-white my-12 md:my-20 lg:my-48">
      <div className="container text-center relative z-20">
        <div className="relative">
          <Swiper
            slidesPerView="auto"
            className="scrollbar-settings !overflow-visible !pb-8"
            centeredSlides
            spaceBetween={26}
          >
            {testimonials?.map((testimonial, j) => (
              <SwiperSlide
                key={j}
                className="!h-auto text-white bg-navy px-6 md:px-8 py-12 md:pt-16 lg:pt-20 md:pb-20 lg:pb-24 relative"
              >
                <div className="absolute flex h-2 w-full top-0 inset-x-0">
                  <div className="w-1/3 bg-teal" />
                  <div className="w-1/3 bg-newPurple" />
                  <div className="w-1/3 bg-newBlue" />
                </div>
                <div className="max-w-2xl mx-auto lg:px-6">
                  <div className="mb-5 md:mb-6">
                    <span className="font-medium">
                      {testimonial?.name + ' '}
                    </span>
                    —{' ' + testimonial?.role}
                  </div>
                  <p
                    className="blockquote-small"
                    dangerouslySetInnerHTML={{ __html: testimonial?.quote }}
                  />
                  <div className="relative h-6 lg:h-8 mt-8 md:mt-12">
                    <Image
                      data={testimonial?.logo}
                      className="w-full h-full contain-image opacity-50"
                      loading="eager"
                    />
                  </div>
                </div>
                <div className="absolute top-4 right-4 lg:top-8 lg:right-8">
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8869 7.66816V8.55605C18.8869 10.4933 18.3832 11.9462 17.3759 12.9955C16.3686 14.0448 14.8577 14.6099 13.0109 14.7713V18C16.0328 18 18.4672 17.0314 20.3139 15.2556C22.0767 13.4798 23 11.139 23 8.23318V0H15.5292V7.66816H18.8869ZM5.87589 7.66816V8.55605C5.87589 10.4933 5.37225 11.9462 4.36495 12.9955C3.35765 14.0448 1.84672 14.6099 0 14.7713V18C3.0219 18 5.45622 17.0314 7.30294 15.2556C9.06571 13.4798 9.98906 11.139 9.98906 8.23318V0H2.51824V7.66816H5.87589Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
