import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';

interface TextImgFwProps {
  subheading: string;
  heading: string;
  content: string;
  image: string;
}

const TextImgFw: FunctionComponent<TextImgFwProps> = ({ ...layoutProps }) => {
  return (
    <section className="bg-lgrey py-12 md:py-24">
      <div className="container text-center">
        <TextCard
          subheading={layoutProps.subheading}
          heading={layoutProps.heading}
          content={layoutProps.content}
          contentClasses="max-w-md mx-auto lg:max-w-2xl"
        />
        <Image data={layoutProps.image} className="w-full mt-8 md:mt-12" />
      </div>
    </section>
  );
};

export default TextImgFw;
