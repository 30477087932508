import React, { FunctionComponent, ReactElement } from 'react';

interface TextProps {
  subheading?: string;
  heading?: string;
  content?: string;
  headingClasses?: string;
  contentClasses?: string;
  subheadingClasses?: string;
}

const TextCard: FunctionComponent<TextProps> = ({
  subheading,
  heading,
  content,
  headingClasses,
  contentClasses,
  subheadingClasses,
}): ReactElement => {
  return (
    <>
      {subheading && (
        <div
          className={`font-medium text-navy opacity-50 ${subheadingClasses}`}
          dangerouslySetInnerHTML={{
            __html: subheading,
          }}
        />
      )}

      {heading && (
        <h2
          className={`${headingClasses}`}
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
      )}
      {content && (
        <div
          className={`${contentClasses}`}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      )}
    </>
  );
};
export default TextCard;
