import React, { FunctionComponent } from 'react';
import TextCardTwoCol from '~/components/Global/TextCardTwoCol';
import Image from '~/components/Image';

interface TwoColTextFWImgProps {
  backgroundColour: string;
  subheading: string;
  heading: string;
  content: string;
  image: string;
}

const TwoColTextFWImg: FunctionComponent<TwoColTextFWImgProps> = ({
  ...layoutProps
}) => {
  return (
    <section
      className={`${
        layoutProps.backgroundColour != 'white'
          ? `py-12 md:py-24 bg-${layoutProps.backgroundColour}`
          : 'my-12 md:my-24'
      }`}
    >
      <div className={`container`}>
        <TextCardTwoCol
          subheading={layoutProps.subheading}
          heading={layoutProps.heading}
          content={layoutProps.content}
        />
        <Image data={layoutProps.image} className="w-full mt-12" />
      </div>
    </section>
  );
};

export default TwoColTextFWImg;
