import { AnimatePresence, motion } from 'framer-motion';

import React, { FunctionComponent, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.min.css';
import Image from '../Image';

interface CardSliderProps {
  subheading: string;
  heading: string;
  cards: [
    {
      image: string;
      heading: string;
      content: string;
      link: {
        url: string;
        title: string;
      };
    }
  ];
}

const CardSlider: FunctionComponent<CardSliderProps> = ({
  heading,
  subheading,
  cards,
}) => {
  const swiperRef = useRef(null);

  const [activeCard, setActiveCard] = useState(-1);

  return (
    <section className="bg-lgrey pt-10 pb-12 md:pt-29 md:pb-40">
      <div className="container relative overflow-visible">
        <div className="relative flex flex-col items-center md:justify-between md:flex-row md:items-end mb-6 md:mb-8 lg:mb-20 text-center md:text-left gap-y-5 md:gap-y-0">
          <div className="max-w-sxl">
            <div
              className={`font-medium text-navy opacity-50`}
              dangerouslySetInnerHTML={{
                __html: subheading,
              }}
            />
            <h2 dangerouslySetInnerHTML={{ __html: heading }} />
          </div>
          <div className="flex items-center gap-x-2">
            <button
              type="button"
              className={`prev cursor-pointer card-slider-btn`}
              onClick={() => swiperRef.current.swiper.slidePrev()}
            >
              <svg
                className="prev-arrow w-full h-full object-contain"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4773 9.66677L9.00732 5.19677L10.1857 4.01843L16.6673 10.5001L10.1857 16.9818L9.00732 15.8034L13.4773 11.3334H3.33398V9.66677H13.4773Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              type="button"
              className={`next cursor-pointer card-slider-btn`}
              onClick={() => swiperRef.current.swiper.slideNext()}
            >
              <svg
                className="w-full h-full object-contain"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4773 9.66677L9.00732 5.19677L10.1857 4.01843L16.6673 10.5001L10.1857 16.9818L9.00732 15.8034L13.4773 11.3334H3.33398V9.66677H13.4773Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div>
        <Swiper
          ref={swiperRef}
          slidesPerView="auto"
          className="card-slider-swiper !overflow-visible"
          spaceBetween={24}
        >
          {cards?.map((card, j) => (
            <SwiperSlide
              key={j}
              className="!h-auto w-full max-w-xxs md:max-w-smlr relative card-slider_item"
            >
              <div className="w-4/5 mx-auto h-2 bg-dgrey" />
              <motion.a
                href={card.link.url}
                className="p-4 block bg-white border border-dgrey text-center transition-all duration-300"
                onMouseEnter={() => setActiveCard(j)}
                onMouseLeave={() => setActiveCard(-1)}
              >
                <div className="w-full overflow-hidden aspect-card-slider mb-5 md:mb-6 ">
                  <Image
                    data={card?.image}
                    className="w-full h-full cover-image"
                  />
                </div>
                <h4 dangerouslySetInnerHTML={{ __html: card.heading }} />
                <p
                  className="max-w-xxs mx-auto leading-tight text-body mb-5"
                  dangerouslySetInnerHTML={{ __html: card.content }}
                />
                <AnimatePresence>
                  {activeCard === j && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <button className="w-full py-2">{card.link.title}</button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default CardSlider;
