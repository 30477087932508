import React, { FunctionComponent } from 'react';
import Image from '~/components/Image';

interface OldWayNewWayProps {
  section: [
    {
      heading: string;
      content: string;
      icon: string;
    }
  ];
}

const OldWayNewWay: FunctionComponent<OldWayNewWayProps> = ({
  ...layoutProps
}) => {
  return (
    <section className="my-12 md:my-24">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-12 xl:gap-24 max-w-2xl mx-auto">
          {layoutProps.section.map(({ heading, icon, content }, i) => (
            <div key={`section-${heading}-${i}`} className="text-center">
              <Image data={icon} className="h-10 mx-auto mb-4" />
              <h4>{heading}</h4>
              <div>{content}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OldWayNewWay;
