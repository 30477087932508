import React, { FunctionComponent } from 'react';
import Image from '~/components/Image';

interface CharityCardProps {
  card: {
    logo: object;
    charityName: string;
  };
}

const CharityCard: FunctionComponent<CharityCardProps> = ({
  card: { logo, charityName },
}) => {
  return (
    <div className="partner bg-white text-center flex flex-col justify-center mx-5 py-4 md:py-8 border border-dgrey shadow-solid">
      <Image data={logo} className="mx-auto w-8 h-8 md:w-16 md:h-16" />
      <p className="font-medium mt-2 md:mt-4 mb-2">{charityName}</p>
      <div className="verified-tag leading-none py-1 px-2 rounded font-medium text-teal bg-teal bg-opacity-25 uppercase mx-auto mb-2 md:mb-4">
        Verified
      </div>
      <ul className="text-left mx-auto">
        {[
          'Passed KYCh checks',
          'Passed financial checks',
          'Anti money laundering',
        ].map((checkItem) => (
          <li
            key={`${charityName}-${checkItem}`}
            className="md:my-2 flex items-center"
          >
            {checkItem}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CharityCard;
