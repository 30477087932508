import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';
interface PerksProps {
  heading: string;
  content: string;
  perk: [
    {
      heading: string;
      description: string;
      icon: {};
    }
  ];
}

const Perks: FunctionComponent<PerksProps> = ({ ...layoutProps }) => {
  return (
    <section className="perks my-12 md:my-24">
      <div className="container text-center">
        <TextCard heading={layoutProps.heading} content={layoutProps.content} />
        <div className="grid md:grid-cols-3 gap-10 md:row-gap-14 md:col-gap-8 mt-12 md:mt-20">
          {layoutProps.perk.map(({ icon, heading, description }, i) => (
            <div key={`perkscol${i}`}>
              <div className="w-12 h-12 mx-auto mb-6 flex flex-col items-center justify-center">
                <Image data={icon} />
              </div>
              <h4>{heading}</h4>
              <div className="text-xs max-w-xxs mx-auto lg:px-6">
                {description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Perks;
