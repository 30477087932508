import { AnimatePresence, motion } from 'framer-motion';
import React, { FunctionComponent, useState } from 'react';
interface FaqsProps {
  heading: string;
  faqs: [
    {
      question: string;
      answer: string;
    }
  ];
}

const Faqs: FunctionComponent<FaqsProps> = ({ heading, faqs }) => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  return (
    <section className="bg-lgrey py-12 md:py-20 lg:py-36">
      <div className="container mb-6 md:mb-8">
        <h2
          className="t-h3 text-center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </div>
      <div className="container">
        <div className="max-w-2xl mx-auto grid gap-y-4 md:gap-y-5">
          {faqs?.map((item, l) => (
            <div
              key={l}
              className={`border-b pb-4 md:pb-5 transition-colors duration-200 ${
                activeQuestion === l ? 'border-red' : 'border-darkBorderGrey'
              }`}
            >
              <div
                className="w-full font-medium text-red cursor-pointer flex items-center justify-between space-x-3"
                onClick={() => setActiveQuestion(l)}
              >
                <span dangerouslySetInnerHTML={{ __html: item?.question }} />
                <span className="text-red w-8 text-left inline-block">
                  {activeQuestion === l ? '-' : '+'}
                </span>
              </div>
              <AnimatePresence>
                {activeQuestion === l && (
                  <motion.div
                    className="overflow-hidden"
                    initial={{ height: 0, opacity: 1 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{
                      duration: 0.3,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <div
                      className="pt-4 md:pt-5"
                      dangerouslySetInnerHTML={{ __html: item?.answer }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
