import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Accordion from '../Global/Accordion';
import TextCard from '../Global/TextCard';

interface CharitiesFAQsProps {
  subheading: string;
  heading: string;
  content: string;
  faq: [
    {
      question: string;
      answer: string;
    }
  ];
}

const CharitiesFAQs: FunctionComponent<CharitiesFAQsProps> = ({
  ...layoutProps
}) => {
  return (
    <section className="my-12 md:my-24 text-center">
      <div className="container">
        <div className="max-w-2xl mx-auto text-left md:text-center">
          <TextCard
            subheading={layoutProps.subheading}
            heading={layoutProps.heading}
            content={layoutProps.content}
            contentClasses="max-w-sm mx-auto"
          />
          <div className="mt-12 border-t border-dgrey">
            {layoutProps.faq.map(({ question, answer }, i) => (
              <Accordion key={`charities-faq-${i}`} title={question}>
                {answer}
              </Accordion>
            ))}
          </div>
          <Link to="" className="text-link">
            Find out more
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CharitiesFAQs;
