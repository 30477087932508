import React, { FunctionComponent, useMemo } from 'react';
import Lottie from '~/components/Global/LottieAnimation';

const DonationsPurpose: FunctionComponent = ({ ...layoutProps }) => {
  return useMemo(
    () => (
      <section className="bg-lgrey py-12 md:py-24 overflow-x-hidden">
        <div className="mb-6 md:-mb-16 xl:-mb-32 container relative z-10">
          <h2 className="max-w-md mx-auto text-center">
            {layoutProps.heading}
          </h2>
        </div>
        <div className="max-w-1600px mx-auto">
          <div className="pointer-events-none z-0">
            <Lottie
              lazyData={'percent-donations-benefits'}
              play={true}
              rendererSettings={{ progressiveLoad: true }}
            />
          </div>
        </div>
        <div className="mt-6 md:-mt-16 xl:-mt-32 container relative z-10">
          <div
            className="max-w-lg mx-auto text-center text-navy"
            dangerouslySetInnerHTML={{
              __html: layoutProps.content,
            }}
          />
        </div>
      </section>
    ),
    []
  );
};

export default DonationsPurpose;
