import React from 'react';
import TextCard from '~/components/Global/TextCard';
import Star from '~/images/icons/star.svg';

interface ExtendedPerksProps {
  heading: string;
  perk: [
    {
      content: string;
    }
  ];
}

const ExtendedPerks: React.FunctionComponent<ExtendedPerksProps> = ({
  ...layoutProps
}) => {
  return (
    <section className="extended-perks pt-12 md:pt-24 mb-12 md:mb-24">
      <div className="container text-center">
        <TextCard heading={layoutProps.heading} />
        <div className="grid md:grid-cols-3 gap-8 md:row-gap-12 md:col-gap-8 mt-12 md:mt-24">
          {layoutProps.perk.map(({ content }, i) => (
            <div key={`extended-perk-${i}`} className="flex text-left">
              <div className="w-8 h-8 rounded-circle bg-red bg-opacity-10 flex-shrink-0 mr-4 flex items-center justify-center">
                <Star />
              </div>
              <div>{content}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExtendedPerks;
