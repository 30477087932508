import React, { FunctionComponent, ReactElement } from 'react';
import Image from '~/components/Image';

interface Props {
  backgroundColour: string;
  columns: string;
  column: [
    {
      icon: string;
      heading: string;
      content: string;
    }
  ];
}

const FeaturesContentGrid: FunctionComponent<Props> = ({
  ...layoutProps
}): ReactElement => {
  const gridClasses = [''];
  if (layoutProps?.columns === '4') {
    gridClasses.push(
      'sm:grid-cols-2 md:grid-cols-4 gap-6 md:gap-x-8 lg:gap-x-12 xl:gap-x-24'
    );
  } else {
    gridClasses.push('md:grid-cols-3 gap-8 md:gap-12');
  }
  return (
    <section
      className={`${
        layoutProps.backgroundColour === ('lgrey' || 'navy')
          ? `py-12 md:py-24 bg-${layoutProps.backgroundColour}`
          : 'my-12 md:my-24'
      } ${layoutProps?.columns === '4' ? 'lg:my-40' : ''}`}
    >
      <div className="container">
        <div className={`${gridClasses.join(' ')} grid`}>
          {layoutProps.column.map(({ icon, heading, content }, i) => (
            <div key={`charityfeatcol${i}`} className="w-full text-center">
              <div className="icon w-8 h-8 mx-auto flex items-center justify-center">
                <Image data={icon} className="w-full" />
              </div>
              <h4 className="mt-4 text-navy">
                <strong dangerouslySetInnerHTML={{ __html: heading }} />
              </h4>
              <p
                className="text-sm mt-2"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default FeaturesContentGrid;
