import { Link } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import Image from '~/components/Image';

interface RelatedPagesProps {
  layout: string;
  subheading: string;
  heading: string;
  content: string;
  page: [
    {
      image: string;
      heading: string;
      description: string;
      link: {
        url: string;
        title: string;
      };
    }
  ];
}

const RelatedPages: FunctionComponent<RelatedPagesProps> = ({
  ...layoutProps
}): ReactElement => {
  return (
    <section className="my-12 md:my-24">
      <div className="container">
        {layoutProps?.layout === 'content' ? (
          <div className="text-center max-w-lg mx-auto mb-12 md:mb-24">
            <div className="font-medium text-navy opacity-50">
              {layoutProps?.subheading}
            </div>
            <h2 className="mb-5">{layoutProps.heading}</h2>
            <p className="opacity-70">{layoutProps?.content}</p>
          </div>
        ) : (
          <h2 className="mb-12">{layoutProps.heading}</h2>
        )}
        <div className="grid md:grid-cols-2 gap-8">
          {layoutProps.page.map((page, i) => (
            <div key={page.heading + i}>
              <Image data={page.image} className="w-full mb-8" />
              <h4>{page.heading}</h4>
              <p className="max-w-md">{page.description}</p>
              {page?.link?.url && (
                <Link to={page.link.url} className="text-link">
                  {page.link.title}
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default RelatedPages;
