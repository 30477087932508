import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';
import Image from '~/components/Image';

interface AppProps {
  subheading: string;
  heading: string;
  content: string;
}

const App: FunctionComponent<AppProps> = ({ page = '', ...layoutProps }) => {
  const { wp } = useStaticQuery(graphql`
    query {
      wp {
        options {
          globalOptions {
            useCases {
              cryptoImage {
                ...Image
              }
              bankingImage {
                ...Image
              }
              donationsImage {
                ...Image
              }
              loyaltyPoints {
                ...Image
              }
              ecommerceImage {
                ...Image
              }
              carbonImage {
                ...Image
              }
            }
          }
        }
      }
    }
  `);

  const {
    link,
    cryptoImage,
    bankingImage,
    donationsImage,
    loyaltyPoints,
    ecommerceImage,
    carbonImage,
  } = wp.options.globalOptions.useCases;

  return (
    <section className="my-12 use-cases-grid md:my-24">
      <div className="container">
        <div className="w-full mb-10 md:mb-4 md:hidden">
          <TextCard
            subheading={layoutProps.subheading}
            heading={layoutProps.heading}
            content={layoutProps.content}
          />
          {link && page !== 'donations' && (
            <Link to={link.url} className="text-link">
              {link.title}
            </Link>
          )}
        </div>
        <div className="flex -ml-4 md:-ml-8">
          {/* use cases left col */}
          <div className="pl-4 left-col md:pl-8">
            <div className="hidden mb-24 md:block">
              <TextCard
                subheading={layoutProps.subheading}
                heading={layoutProps.heading}
                content={layoutProps.content}
              />
              {link && page !== 'donations' && (
                <Link to={link.url} className="text-link">
                  {link.title}
                </Link>
              )}
            </div>
            <div className="mt-0 mb-4 -ml-32 text-center md:ml-0 md:mb-0">
              <Image data={cryptoImage} className="w-full crypto-img" />
              <div className="hidden my-6 text-xs font-medium opacity-50 text-navy md:block">
                Build giving into Crypto
              </div>
            </div>
            <div className="-ml-32 text-center md:-ml-56">
              <Image data={bankingImage} className="w-full banking-img" />
              <div className="hidden my-6 text-xs font-medium opacity-50 text-navy md:ml-56 md:block">
                Build giving into Banking
              </div>
            </div>
          </div>
          {/* use cases center col */}
          <div className="pl-4 center-col md:pl-8">
            <div className="mt-4 mb-4 text-center md:mt-24 md:mb-0">
              <Image data={donationsImage} className="w-full donations-img" />
              <div className="hidden my-6 text-xs font-medium opacity-50 text-navy md:block">
                Build giving into Payments
              </div>
            </div>
            <div className="text-center">
              <Image
                data={loyaltyPoints}
                className="w-full loyalty-points-img"
              />
              <div className="hidden my-6 text-xs font-medium opacity-50 text-navy md:block">
                Build giving into Loyalty Points
              </div>
            </div>
          </div>
          {/* use cases right col */}
          <div className="pl-4 right-col md:pl-8">
            <div className="mb-4 text-center md:mb-0">
              <Image data={ecommerceImage} className="w-full ecommerce-img" />
              <div className="hidden my-6 text-xs font-medium opacity-50 text-navy md:block">
                Build giving into e-Commerce
              </div>
            </div>
            <div className="text-center">
              <Image data={carbonImage} className="w-full carbon-img" />
              <div className="hidden my-6 text-xs font-medium opacity-50 text-navy md:block">
                Build giving into Currency Exchange
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default App;
