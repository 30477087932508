import { Link } from 'gatsby';
import React, { FunctionComponent, useEffect, useState } from 'react';
import useIsInViewport from 'use-is-in-viewport';
import LottieAnimation from '~/components/Global/LottieAnimation';
import Image from '~/components/Image';
interface BoxProps {
  subheading: string;
  heading: string;
  content: string;
  links?: [
    {
      link: {
        title: string;
        url: string;
      };
    }
  ];
  image?: string;
  imageType: string;
  animationName?: string;
}
interface FWFeatureBoxesTwoColProps {
  heading: string;
  featureBox: [BoxProps];
}

const Box: FunctionComponent<BoxProps> = ({
  heading,
  subheading,
  content,
  links,
  imageType,
  image,
  animationName,
}) => {
  const [isInViewport, sectionRef] = useIsInViewport({ threshold: 50 });
  const [wasInViewportOnce, setWasInViewportOnce] = useState(false);

  useEffect(() => {
    if (isInViewport === true) {
      setWasInViewportOnce(true);
    }
  }, [isInViewport]);

  return (
    <div
      ref={sectionRef}
      className="bg-lgrey grid md:grid-cols-2 gap-8 md:gap-24 p-8 md:p-12 xl:px-24 mt-4 transition duration-500 ease-out"
      style={{
        opacity: wasInViewportOnce ? 1 : 0,
        transform: `translateY(${wasInViewportOnce ? 0 : '50px'})`,
      }}
    >
      <div className="flex items-center">
        <div>
          <div
            className="font-medium text-navy opacity-50"
            dangerouslySetInnerHTML={{
              __html: subheading,
            }}
          />
          <h3
            className="max-w-xs"
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />

          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
          <div className="mt-5">
            {links
              .filter(({ link }) => link && link.url?.length)
              .map(({ link }, i) => (
                <div key={`${heading}-link-${i}`}>
                  <Link to={link.url} className="text-link">
                    {link.title}
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div>
        {(typeof imageType === 'undefined' || imageType === 'image') && (
          <Image data={image} className="w-full" />
        )}
        {imageType === 'animation' && animationName && (
          <LottieAnimation
            loop={false}
            play={isInViewport}
            lazyData={animationName}
            className="w-full"
          />
        )}
      </div>
    </div>
  );
};

const FWFeatureBoxesTwoCol: FunctionComponent<FWFeatureBoxesTwoColProps> = ({
  ...layoutProps
}) => {
  return (
    <section className="my-12 md:my-24">
      <div className="container">
        <h2 className="text-center mb-12">{layoutProps.heading}</h2>

        {layoutProps.featureBox.map((props) => (
          <Box key={`feature-box-${props.heading}`} {...props} />
        ))}
      </div>
    </section>
  );
};

export default FWFeatureBoxesTwoCol;
