import { motion, useAnimation } from 'framer-motion';
import { Link } from 'gatsby';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Image from '../Image';

interface TwoColHeroBleedProps {
  heading: string;
  content: string;
  link: {
    url: string;
    title: string;
  };
  image: string;
}

const TwoColHeroBleed: FunctionComponent<TwoColHeroBleedProps> = ({
  heading,
  content,
  link,
  image,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section className="bg-white validation-masthead py-8 md:py-12 lg:py-14 overflow-hidden">
      <div className="container grid gap-y-8 md:gap-y-0 md:gap-x-6 grid-cols-12 items-center">
        <div className="col-span-12 md:col-span-6 lg:col-span-5 relative">
          <h1 className="mb-5" dangerouslySetInnerHTML={{ __html: heading }} />
          <div
            className="max-w-sm alt-hero-content mb-5 md:mb-8"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {link?.url && (
            <Link to={link?.url} className="btn">
              {link?.title}
            </Link>
          )}
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-start-7">
          <motion.div
            className="hero-bleed_image"
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
              visible: { opacity: 1, x: 0 },
              hidden: { opacity: 0, x: 50 },
            }}
            transition={{ duration: 0.5 }}
          >
            <Image data={image} className="w-full" loading="eager" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default TwoColHeroBleed;
