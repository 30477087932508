import React, { FunctionComponent, ReactElement } from 'react';
import OurCustomers from '~/components/About/OurCustomers';
import OurStory from '~/components/About/OurStory';
import OurValues from '~/components/About/OurValues';
import PurposeStack from '~/components/About/PurposeStack';
import ExtendedPerks from '~/components/Careers/ExtendedPerks';
import Perks from '~/components/Careers/Perks';
import Vacancies from '~/components/Careers/Vacancies';
import CareersValues from '~/components/Careers/Values';
import CharitiesFAQs from '~/components/Charities/CharitiesFAQs';
import OldWayNewWay from '~/components/Disbursements/OldWayNewWay';
import VettingAndValidation from '~/components/Disbursements/VettingAndValidation';
import DonationsBeforeAfter from '~/components/Donations/DonationsBeforeAfter';
import DonationsPurpose from '~/components/Donations/Purpose';
import CTA from '~/components/Global/CTA';
import Compliance from '~/components/Global/Compliance';
import FWFeatureBoxesTwoCol from '~/components/Global/FWFeatureBoxesTwoCol';
import FeaturesContentGrid from '~/components/Global/FeaturesContentGrid';
import RelatedPages from '~/components/Global/RelatedPages';
import SecurityPanel from '~/components/Global/SecurityPanel';
import TabsFWImg from '~/components/Global/TabsFWImg';
import Testimonial from '~/components/Global/TestimonialFC';
import TextImgFW from '~/components/Global/TextImgFW';
import TextWLogosGrid from '~/components/Global/TextWLogosGrid';
import TwoColImgText from '~/components/Global/TwoColImgText';
import TwoColTextFWImg from '~/components/Global/TwoColTextFWImg';
import TwoColTextWContentGrid from '~/components/Global/TwoColTextWContentGrid';
import UseCases from '~/components/Global/UseCasesFC';
import DecadeOfPurpose from '~/components/Home/DecadeOfPurpose';
import InlineForm from './GetInTouch/InlineForm';
import GlobeAnimation from './Validations/GlobeAnimation';
import Testimonials from './Validations/Testimonials';
import ValidationHero from './Validations/ValidationHero';
import AltCta from './landing/AltCta';
import CardSlider from './landing/CardSlider';
import Faqs from './landing/Faqs';
import IconBoxes from './landing/IconBoxes';
import IconColumn from './landing/IconColumn';
import LandingHero from './landing/LandingHero';
import LinksList from './landing/LinksList';
import PercentAdvisorCard from './landing/PercentAdvisorCard';
import SimpleTextCard from './landing/SimpleTextCard';
import TwoCardsContent from './landing/TwoCardsContent';
import TwoColHeroBleed from './landing/TwoColHeroBleed';
import TwoColumnSimplePricing from './landing/TwoColumnSimplePricing';
import PricingMatrix from './pricing/PricingMatrix';

const typeMap = {
  WpPage_Layouts_Layouts_TwoColImageText: TwoColImgText,
  WpPage_Layouts_Layouts_Testimonial: Testimonial,
  WpPage_Layouts_Layouts_SecurityPanel: SecurityPanel,
  WpPage_Layouts_Layouts_Compliance: Compliance,
  WpPage_Layouts_Layouts_TextAndImageFullWidth: TextImgFW,
  WpPage_Layouts_Layouts_UseCasesGrid: UseCases,
  WpPage_Layouts_Layouts_TabsWithFwImage: TabsFWImg,
  WpPage_Layouts_Layouts_Cta: CTA,
  WpPage_Layouts_Layouts_VettingAndValidation: VettingAndValidation,
  WpPage_Layouts_Layouts_OldWayNewWay: OldWayNewWay,
  WpPage_Layouts_Layouts_RelatedPages: RelatedPages,
  WpPage_Layouts_Layouts_FullWidthFeatureBoxesTwoCol: FWFeatureBoxesTwoCol,
  WpPage_Layouts_Layouts_DecadeOfPurpose: DecadeOfPurpose,
  WpPage_Layouts_Layouts_TextWithLogosGrid: TextWLogosGrid,
  WpPage_Layouts_Layouts_OurStory: OurStory,
  WpPage_Layouts_Layouts_PurposeStack: PurposeStack,
  WpPage_Layouts_Layouts_OurValues: OurValues,
  WpPage_Layouts_Layouts_OurCustomers: OurCustomers,
  WpPage_Layouts_Layouts_Vacancies: Vacancies,
  WpPage_Layouts_Layouts_Perks: Perks,
  WpPage_Layouts_Layouts_CareersValues: CareersValues,
  WpPage_Layouts_Layouts_ExtendedPerks: ExtendedPerks,
  WpPage_Layouts_Layouts_TwoColTextWithContentGrid: TwoColTextWContentGrid,
  WpPage_Layouts_Layouts_FeaturesContentGrid: FeaturesContentGrid,
  WpPage_Layouts_Layouts_CharitiesFaqs: CharitiesFAQs,
  WpPage_Layouts_Layouts_TwoColTextAndFullWidthImage: TwoColTextFWImg,
  WpPage_Layouts_Layouts_DonationsPurpose: DonationsPurpose,
  WpPage_Layouts_Layouts_DonationsBeforeAfter: DonationsBeforeAfter,
  WpPage_Layouts_Layouts_GlobeAnimation: GlobeAnimation,
  WpPage_Layouts_Layouts_ValidationHero: ValidationHero,
  WpPage_Layouts_Layouts_TestiomnialSlider: Testimonials,
  WpPage_Layouts_Layouts_LandingHero: LandingHero,
  WpPage_Layouts_Layouts_TwoCardsContent: TwoCardsContent,
  WpPage_Layouts_Layouts_PricingMatrix: PricingMatrix,
  WpPage_Layouts_Layouts_TwoColHeroBleed: TwoColHeroBleed,
  WpPage_Layouts_Layouts_IconBoxes: IconBoxes,
  WpPage_Layouts_Layouts_CardSlider: CardSlider,
  WpPage_Layouts_Layouts_IconColumn: IconColumn,
  WpPage_Layouts_Layouts_AltCta: AltCta,
  WpPage_Layouts_Layouts_SimpleTextCard: SimpleTextCard,
  WpPage_Layouts_Layouts_TwoColumnSimplePricing: TwoColumnSimplePricing,
  WpPage_Layouts_Layouts_PercentAdvisorCard: PercentAdvisorCard,
  WpPage_Layouts_Layouts_Faqs: Faqs,
  WpPage_Layouts_Layouts_LinksList: LinksList,
  WpPage_Layouts_Layouts_InlineForm: InlineForm,
};

const FlexibleContent: FunctionComponent<{
  pageName: string;
  layouts?: {
    layouts: any;
  };
}> = ({ layouts = { layouts: [] }, pageName = '' }): ReactElement => {
  return layouts.layouts.map(({ __typename, ...layoutProps }, index) =>
    React.createElement(typeMap[__typename], {
      key: `${pageName}-${index}`,
      ...layoutProps,
    })
  );
};

export default FlexibleContent;
