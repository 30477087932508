import React, { FunctionComponent } from 'react';

const CareersValues: FunctionComponent = ({ ...layoutProps }) => {
  const boxBackgrounds = [
    'shadow-solid-teal',
    'shadow-solid-purple',
    'shadow-solid-blue',
  ];
  return (
    <section className="my-12 md:mt-56 md:mb-24">
      <div className="container">
        <div className="text-center font-medium">{layoutProps.subheading}</div>
        <h2 className="text-center mb-12">{layoutProps.heading}</h2>
        {layoutProps.value.map((val, i) => (
          <div
            key={`career-value-${i}`}
            className={`flex items-end mb-10 ${
              i % 2 === 0 ? 'justify-start' : 'justify-end'
            }`}
          >
            <div
              className={`bg-white shadow-solid border border-dgrey relative p-8 md:p-16 lg:flex lg:justify-start w-full ${
                boxBackgrounds[i % boxBackgrounds.length]
              }`}
            >
              <div className="absolute right-0 md:right-auto md:left-12 bottom-0 text-lgrey  text-200px font-bold leading-none">
                0{i + 1}
              </div>
              <h3 className="lg:w-5/12 mt-0 relative z-10">{val.heading}</h3>
              <div
                className="lg:w-1/2 lg:mt-0 relative z-10 text-xxs md:text-base"
                dangerouslySetInnerHTML={{
                  __html: val.content,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CareersValues;
