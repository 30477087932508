import React, { FunctionComponent } from 'react';
import TextCard from '~/components/Global/TextCard';

interface OurStoryProps {
  subheading: string;
  heading: string;
  content: string;
}

const OurStory: FunctionComponent<OurStoryProps> = ({ ...layoutProps }) => {
  return (
    <section className="bg-lgrey pt-40 -mt-40 pb-12 md:pt-96 md:-mt-96 md:pb-24">
      <div className="container">
        <div className="max-w-md md:max-w-lg mx-auto">
          <TextCard
            subheading={layoutProps.subheading}
            heading={layoutProps.heading}
            content={layoutProps.content}
          />
        </div>
      </div>
    </section>
  );
};

export default OurStory;
